<script setup lang="ts">
import { ref, type Component } from 'vue'
import useSeen from '@/composables/useSeen'

defineProps<{
	is?: Component | string
}>()

const container = ref(null)
const seen = useSeen(container)
</script>

<template>
	<component
		:is="is ?? 'div'"
		ref="container"
		:class="[
			'transition-all duration-1000',
			seen ? 'translate-x-0 opacity-100' : 'translate-y-3 opacity-0',
		]"
	>
		<slot />
	</component>
</template>
