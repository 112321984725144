import { ref, watch } from 'vue'
import { MaybeComputedElementRef, MaybeElement, useElementVisibility } from '@vueuse/core'

export default function useSeen(element: MaybeComputedElementRef<MaybeElement>) {
	const visibility = useElementVisibility(element)
	const seen = ref(false)

	watch(() => visibility.value, (visible) => {
		if (visible) {
			seen.value = true
		}
	})

	return seen
}
