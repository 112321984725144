<script setup lang="ts">
import { useProperty } from '@/composables/useProperty'
import cjAndroid from '~/images/captainjet/company-links/cj-android.webp'

const androidDownloadLink = useProperty('security.features.android_download_link')
</script>

<template>
	<a
		class="group relative flex min-h-48 overflow-hidden rounded-2xl px-6 py-5"
		:href="androidDownloadLink"
		target="_blank"
	>
		<div class="absolute inset-0 flex origin-right items-center bg-success-300 transition-transform duration-500 ease-out group-hover:scale-[108%]">
			<div class="absolute -bottom-72 -right-64 size-[444px] rounded-full bg-blue-500/60 mix-blend-overlay" />
			<img
				:src="cjAndroid"
				alt="CaptainJet Android app"
				class="absolute right-5 h-[150px]"
			/>
		</div>
		<div class="relative flex w-7/12 flex-col items-start justify-between gap-y-5 text-white">
			<div class="flex flex-col gap-y-3">
				<h3 class="text-lg font-medium uppercase leading-5 tracking-[3.6px] sm:text-sm">
					Discover
				</h3>
				<span class="text-[40px] font-medium leading-[90%]">Android app</span>
			</div>
			<div class="flex size-10 items-center justify-center rounded-full bg-success-400">
				<CompanyLinksIconPlay class="ml-0.5 w-[18px]" />
			</div>
		</div>
	</a>
</template>
