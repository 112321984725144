<template>
	<transition
		appear
		enter-active-class="duration-1000 transition"
		enter-from-class="opacity-0 -translate-x-2"
		enter-to-class="opacity-100 translate-x-0"
	>
		<slot />
	</transition>
</template>
