<script setup lang="ts">
const container = ref(null)
const mouse = useMouseInElement(container)
const mouseZDistance = 27000
</script>

<template>
	<div
		ref="container"
		class="flex transition-transform duration-1000 ease-out"
		:style="{
			transform: `
				rotateY(${Math.atan((mouse.elementX.value - 0.5 * mouse.elementWidth.value) / mouseZDistance)}rad)
				rotateX(${-Math.atan((mouse.elementY.value - 0.5 * mouse.elementHeight.value) / mouseZDistance)}rad)
			`,
			transformStyle: 'preserve-3d',
		}"
	>
		<CompanyLinksFadeRight>
			<div
				class="flex max-h-[1098px] flex-1 flex-col gap-y-11 rounded-[30px] px-[70px] py-[76px] backdrop-blur-[20px]"
				:style="{
					background: 'linear-gradient(0, rgba(26, 53, 80, 0.50) 0%, rgba(26, 53, 80, 0.10) 100%)',
				}"
			>
				<div class="flex flex-[0.64] gap-x-12">
					<CompanyLinksWebsitePanel class="max-w-md flex-1" />
					<div class="flex flex-[0.82] flex-col gap-y-10">
						<CompanyLinksIosPanel class="flex-1" />
						<CompanyLinksAndroidPanel class="flex-1" />
					</div>
				</div>
				<div class="flex flex-[0.36] gap-x-10">
					<CompanyLinksContactPanel class="flex-1" />
					<CompanyLinksSocialLinks class="flex flex-col justify-between" />
				</div>
			</div>
		</CompanyLinksFadeRight>
	</div>
</template>
