<script setup lang="ts">
import { breakpointsTailwind } from '@vueuse/core'
import { route } from '@/composables/route'
import cjWeb from '~/images/captainjet/company-links/cj-web.webp'

const breakpoints = useBreakpoints(breakpointsTailwind)
</script>

<template>
	<InertiaLink
		class="group relative min-h-[340px] overflow-hidden rounded-2xl px-6 py-4 sm:min-h-full"
		:href="route('captainjet.web.index')"
	>
		<img
			:src="cjWeb"
			alt="CaptainJet website"
			class="absolute inset-0 size-full origin-right object-cover transition-transform duration-500 ease-out group-hover:scale-[108%]"
			:style="{
				objectPosition: breakpoints.sm ? undefined : 'center -8px',
			}"
		/>
		<div class="relative flex flex-col items-start text-white">
			<h3 class="text-lg font-medium uppercase leading-5 tracking-[3.6px] sm:text-sm">
				Visit our website
			</h3>
			<CompanyLinksIconPlane class="mt-4.5 h-8" />
		</div>
	</InertiaLink>
</template>
