<script setup lang="ts">
import { useProperty } from '@/composables/useProperty'
import cjIos from '~/images/captainjet/company-links/cj-ios.webp'

const iosDownloadLink = useProperty('security.features.ios_download_link')
</script>

<template>
	<a
		class="group relative flex min-h-48 overflow-hidden rounded-2xl px-6 py-5"
		:href="iosDownloadLink"
		target="_blank"
	>
		<div class="absolute inset-0 flex origin-right items-center bg-warning-400 transition-transform duration-500 ease-out group-hover:scale-[108%]">
			<div class="absolute -bottom-72 -right-64 size-[444px] rounded-full bg-blue-500/60 mix-blend-overlay" />
			<img
				:src="cjIos"
				alt="CaptainJet iOS app"
				class="absolute right-5 h-[150px]"
			/>
		</div>
		<div class="relative flex w-7/12 flex-col items-start justify-between gap-y-5 text-white">
			<div class="flex flex-col gap-y-3">
				<h3 class="text-lg font-medium uppercase leading-5 tracking-[3.6px] sm:text-sm">
					Discover
				</h3>
				<span class="text-[40px] font-medium leading-[90%]">iOS app</span>
			</div>
			<div class="flex size-10 items-center justify-center rounded-full bg-warning-500">
				<CompanyLinksIconApple class="mb-px mr-px w-[18px]" />
			</div>
		</div>
	</a>
</template>
