<template>
	<transition
		appear
		enter-active-class="duration-1000 transition-opacity"
		enter-from-class="opacity-0"
		enter-to-class="opacity-100"
		leave-active-class="transition-opacity ease-out"
		leave-from-class="opacity-100"
		leave-to-class="opacity-0"
	>
		<slot />
	</transition>
</template>
