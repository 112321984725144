<script setup lang="ts">
import { route } from '@/composables/route'
import backgroundImage from '~/images/captainjet/company-links/bg-mobile.webp'
import WebsitePanel from '@/components/CompanyLinks/WebsitePanel.vue'
import IosPanel from '@/components/CompanyLinks/IosPanel.vue'
import AndroidPanel from '@/components/CompanyLinks/AndroidPanel.vue'
import ContactPanel from '@/components/CompanyLinks/ContactPanel.vue'

const windowScroll = useWindowScroll()
</script>

<template>
	<div class="flex flex-1 flex-col overflow-hidden">
		<CompanyLinksFadeIn>
			<div class="absolute inset-0 overflow-hidden">
				<img
					:src="backgroundImage"
					alt=""
					class="absolute inset-0 size-full object-cover object-top"
					:style="{
						// Make a parallax effect.
						transform: `translateY(${0.5 * windowScroll.y.value}px)`,
					}"
				/>
			</div>
		</CompanyLinksFadeIn>
		<CompanyLinksFadeUp class="flex flex-col px-4 pb-8 pt-12 text-white">
			<InertiaLink
				title="CaptainJet"
				:href="route('captainjet.web.index')"
				class="self-start"
			>
				<CompanyLinksIconLogo class="w-56" />
			</InertiaLink>
			<div
				class="flex flex-col"
				:style="{
					textShadow: '0px 4px 32.8px #255B8D',
				}"
			>
				<h2 class="mt-11 font-inter text-5xl leading-[94%]">
					When experience meets <b>digital innovation</b>
				</h2>
				<span class="mt-7 font-medium uppercase leading-[87.5%] tracking-[2.5px]">
					Friendly • Reliable • Digital
				</span>
			</div>
		</CompanyLinksFadeUp>
		<div
			class="relative flex flex-1 flex-col p-4 pb-10 backdrop-blur-[20px]"
			:style="{
				background: 'linear-gradient(0, rgba(26, 53, 80, 0.50) 0%, rgba(26, 53, 80, 0.00) 100%)',
				mask: 'linear-gradient(180deg, transparent 0%, black 16px, black 100%)',
			}"
		>
			<div class="flex flex-col gap-y-5">
				<CompanyLinksFadeUp :is="WebsitePanel" />
				<CompanyLinksFadeUp :is="IosPanel" />
				<CompanyLinksFadeUp :is="AndroidPanel" />
				<CompanyLinksFadeUp :is="ContactPanel" />
			</div>
			<CompanyLinksFadeUp class="mt-8 flex justify-center">
				<CompanyLinksSocialLinks class="flex max-w-72 flex-1 justify-between" />
			</CompanyLinksFadeUp>
		</div>
	</div>
</template>
